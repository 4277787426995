import "../assets/css/normalize.css";
import "../assets/css/style.css";
import {
  getParameterByName
} from "./query-param";
import {
  appendView
} from "./append-view";
const parameters = require('../../parameters.json');

var data;
var companyId;
var customerId;

export async function onInit() {

  companyId = getParameterByName("company_id");
  customerId = getParameterByName("customer_id");

  showSpinner();

  if (companyId) {

    let response = await fetch(`${parameters.API_URL}/api/companies/current-office/${companyId}`);
    let result = await response.json();

    data = result.data;
    let company = data.company;

    let element = document.getElementById("header");
    element.innerHTML = company;

    // Temporalmente agregamos conexion con zillow para Interstate Home Loan Cebter
    // Eliminar esta linea cdo se implemente la conexion de plataforma con zillow
    if (companyId == 581){
      //data.zillow = "https://www.zillow.com/lender-profile/inquiries55/"
      data.zillow = "https://www.zillow.com/mortgage/lender-review/?screenName=inquiries55"
    }

    const negativeReviewProtection = data.negative_review_protection;

    if (negativeReviewProtection == 1) {
      showQuestion();
    } else {
      showPlatform();
    }
  } else {
    showNotFound();
  }
}

export function showQuestion() {
  const element = `
      <div class="container">
        <div class="title">How was your experience with us?</div>
        <div class="row">
          <a class="btn button-great" onclick="showPlatform()">GREAT!</a>
          <a class="btn button-bad" onclick="showBad()">Not That Good</a>
        </div>
      </div>
  `;

  appendView(element);
}

export async function showPlatform() {

  const element = `
    <div class="container">
      <div class="title">How was your experience with us?</div>
      <div class="subtitle">Thank you for giving us the opportunity to serve you. Please leave us a review using one of the options below.</div>
      <div class="row">
        ${(data.goog) ? `<a class="btn button-google" href="${data.goog}">Google</a>` : ''}
        ${(data.face) ? `<a class="btn button-facebook" href="${data.face}">Facebook</a>` : ''}
        ${(data.zillow) ? `<a class="btn button-zillow" href="${data.zillow}">Zillow</a>` : ''}
      </div>
    </div>
  `;

  appendView(element);
}

export async function showNotFound() {

  const element = `
    <div class="container">
      <div class="title">NOT FOUND!</div>
    </div>
  `;

  appendView(element);
}

export async function showSpinner() {

  const element = `
    <div class="container">
      <div class="title">Loading...</div>
    </div>
  `;

  appendView(element);
}

export async function showBad() {

  const element = `
    <div class="container">
      <div class="title">Send us your feedback.</div>
      <br>
      <div class="row">
        <form>
          <textarea class="textarea ui-autocomplete-input" placeholder="Write your review here..." id="message" rows="10" name="comment[text]" cols="40" autocomplete="off" role="textbox" aria-autocomplete="list" aria-haspopup="true" onkeyup="setBtnDisabled()"></textarea>
          <br>
          <button class="btn button-send" type="button" onclick="send()" id="btnSend" disabled>Send</button>
        </form>
      </div>
    </div>
  `;

  appendView(element);
}

export async function send() {
  const message = document.getElementById("message").value;

  let headers = new Headers();
  headers.set('Authorization', 'Basic ' + btoa("nms" + ":" + "nms*2289-"));
  headers.set("Accept", "application/json");
  headers.set("Content-Type", "application/json");

  let response = await fetch(`${parameters.API_URL}/ext/rp-complaint`, {
    headers: headers,
    method: "POST",
    body: JSON.stringify({
      company_id: companyId,
      customer_text: message,
      customer_id: customerId
    })
  });

  if (response.status >= 200 && response.status < 300) {
    document.getElementById("message").value = "";
    document.getElementById('btnSend').disabled = true;

    var x = document.getElementById("snackbar");

    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  }
}

export function setBtnDisabled() {
  if (document.getElementById("message").value === "") {
    document.getElementById('btnSend').disabled = true;
  } else {
    document.getElementById('btnSend').disabled = false;
  }
}


window.showPlatform = showPlatform;
window.showBad = showBad;
window.send = send;
window.setBtnDisabled = setBtnDisabled;

onInit();
